import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as ChevronDown } from '../images/svgs/chevron-down-solid.svg';
import { ReactComponent as LJIcon } from '../images/svgs/lj-icon.svg';

import styles from '../styles/components/hero.module.scss';

const Hero = ({ imgSrc, text, hasIcon }) => (
  <div className={styles.hero}>
    <img src={imgSrc} alt="" />
    <div className={styles.heroTextArea}>
      <h1>{text}</h1>
    </div>
    {hasIcon && (
      <div className={styles.icon}>
        <LJIcon />
      </div>
    )}
    <div className={styles.chevrons}>
      <ChevronDown />
      <ChevronDown />
      <ChevronDown />
    </div>
  </div>
);

Hero.defaultProps = {
  sectionTitle: '',
  title: '',
  hasIcon: false,
};

Hero.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string,
  hasIcon: PropTypes.bool,
};

export default Hero;
