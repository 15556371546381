import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

import styles from './navigation.module.scss';

const NavButton = ({ path, title, isPrimaryColor }) => {
  const buttonStyle = classNames(styles.container, {
    [styles.primaryColor]: isPrimaryColor,
    [styles.secondaryColor]: !isPrimaryColor,
  });

  return (
    <div className={buttonStyle}>
      <Link to={path} className={styles.link}>
        <h2>{title}</h2>
      </Link>
    </div>
  );
};

NavButton.defaultProps = {
  isPrimaryColor: true,
};

NavButton.propTypes = {
  isPrimaryColor: PropTypes.bool,
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default NavButton;
