import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import ContentWrapper from '../contentWrapper/contentWrapper';
import NavButton from '../buttons/navigation';

import { ReactComponent as ChevronDown } from '../../images/svgs/chevron-down.svg';

import { menuPaths } from '../../constants/menuPaths';

import styles from './messageLJ.module.scss';

const MessageLJ = ({ intl, showChevrons, isVertical }) => {
  const containerStyle = classNames(styles.container, {
    [styles.containerVertical]: isVertical,
  });

  const textColor = classNames(
    styles.text,
    { [styles.textVertical]: isVertical },
    { [styles.textHorizontal]: !isVertical }
  );

  const language = intl.locale;
  const path = `/${language}/${menuPaths.messageLJ[language]}`;

  const isPrimaryColor = isVertical ? true : false;

  return (
    <ContentWrapper>
      <div className={containerStyle}>
        <div className={textColor}>
          <FormattedMessage id="home.writeToLJ.text" />
        </div>
        {showChevrons && (
          <div className={styles.chevronContainer}>
            <ChevronDown />
            <ChevronDown />
            <ChevronDown />
          </div>
        )}
        <NavButton
          path={path}
          title={intl.formatMessage({ id: 'menu.messageLJ' })}
          isPrimaryColor={isPrimaryColor}
        />
      </div>
    </ContentWrapper>
  );
};

MessageLJ.defaultProps = {
  isVertical: false,
  showChevrons: false,
};

MessageLJ.propTypes = {
  intl: intlShape.isRequired,
  isVertical: PropTypes.bool,
  showChevrons: PropTypes.bool,
};

export default injectIntl(MessageLJ);
