import React from 'react';
import { Link } from 'gatsby';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { menuPaths } from '../constants/menuPaths';

import ContentWrapper from '../components/contentWrapper/contentWrapper';
import Hero from '../components/hero';
import MessageLJ from '../components/messageLJ/messageLJ';

import coverImage from '../images/home-cover-3.jpeg';
import homeLJ from '../images/home-lj.png';
import { ReactComponent as Leaf } from '../images/svgs/home-leaf.svg';

import styles from '../styles/pagesComponents/homePage.module.scss';

const PageComponent = ({ intl }) => {
  const language = intl.locale;
  const messagesPath = `/${language}/${menuPaths.messages[language]}`;
  const happinessCornerPath = `/${language}/${menuPaths.happinessCorner[language]}`;

  const primaryButtonStyle = classNames(
    styles.navButton,
    styles.primaryNavButton
  );
  const secondaryButtonStyle = classNames(
    styles.navButton,
    styles.secondaryNavButton
  );

  return (
    <div>
      <Helmet>
        <title>{intl.formatMessage({ id: 'menu.home' })}</title>
        <meta
          name="description"
          content={intl.formatMessage({ id: 'meta.description.home' })}
        />
        <meta
          property="og:title"
          content={intl.formatMessage({ id: 'menu.home' })}
        />
        <meta
          property="og:description"
          content={intl.formatMessage({ id: 'meta.description.home' })}
        />
      </Helmet>
      <Hero
        imgSrc={coverImage}
        text={intl.formatMessage({ id: 'home.title.phrase' })}
        hasIcon
      />
      <ContentWrapper>
        <div className={styles.imageContainer}>
          <img
            src={homeLJ}
            alt={intl.formatMessage({ id: 'altText.home.LJ' })}
          />
        </div>
        <div className={styles.aboutLJ}>
          <h2>
            <FormattedMessage id="home.content.title" />
          </h2>
          <div className={styles.paragraph}>
            <div className={styles.subParagraph}>
              <strong>
                <FormattedMessage id="aboutLJ.founder.first" />
              </strong>
              <FormattedMessage id="aboutLJ.founder.second" />
              <a href="https://www.msfint.com" rel="noreferrer" target="_blank">
                <strong>
                  <FormattedMessage id="aboutLJ.founder.third" />
                </strong>
              </a>
              <FormattedMessage id="aboutLJ.founder.fourth" />
              <strong>
                <FormattedMessage id="aboutLJ.founder.fifth" />
              </strong>
            </div>
            <FormattedMessage id="aboutLJ.5Minutes" />
            <div className={styles.subParagraph}>
              <strong>
                <FormattedMessage id="aboutLJ.lifework.first" />
              </strong>
              <FormattedMessage id="aboutLJ.lifework.second" />
            </div>
            <FormattedMessage id="aboutLJ.messages" />
          </div>
        </div>
      </ContentWrapper>
      <ContentWrapper>
        <div className={primaryButtonStyle}>
          <Link to={happinessCornerPath} className={styles.link}>
            <FormattedMessage id="home.buttons.phraseCorner" />
            <h2>
              <FormattedMessage id="home.buttons.corner" />
            </h2>
          </Link>
        </div>
        <div className={secondaryButtonStyle}>
          <Link to={messagesPath} className={styles.link}>
            <FormattedMessage id="home.buttons.phraseMessages" />
            <h2>
              <FormattedMessage id="home.buttons.messages" />
            </h2>
          </Link>
        </div>
      </ContentWrapper>
      <div className={styles.leafContainer}>
        <Leaf />
      </div>
      <MessageLJ isVertical showChevrons />
    </div>
  );
};

PageComponent.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(PageComponent);
